import React, { useState, forwardRef, useImperativeHandle } from "react";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import LazyLoad from "react-lazyload";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, useTheme } from "@material-ui/core/";

import CardListPlaceholder from "../components/CardListPlaceholder";
import Global from "../assets/global";
import Helper from "../components/Helper";

const useStyles = makeStyles(theme => ({
	cardThumbNail: {
		width: "90%",
		transition: "all ease-in-out 2s",
		"&:hover": {
			transition: "all ease-in-out 0.5s"
		}
	}
}));

// RIGHT UI REGION
const CardList = forwardRef((props, ref) => {
	const masterCardList = Array.from(Helper.getSetData()).sort((a, b) => {
		return a["cost"] - b["cost"];
	});
	const [cardListState, setCardListState] = useState(masterCardList);
	const c = useStyles();
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

	let imgSrcPrefix = Global.IMG_PREFIX;
	imgSrcPrefix += isMobile ? "scards/" : "cards/";

	// Explicitly forwarded the state hook
	useImperativeHandle(ref, () => ({
		setCardListState
	}));

	if (cardListState) {
		return Object.keys(cardListState).map((key, i) => {
			return (
				<Grid item xs={5} sm={3} md={3} lg={2} key={key}>
					<a href={"/cards/" + cardListState[key]["cardCode"]}>
						<LazyLoad placeholder={<CardListPlaceholder />}>
							<img
								src={
									imgSrcPrefix +
									cardListState[key]["cardCode"] +
									Global.EXT
								}
								className={c.cardThumbNail}
								alt=""
							/>
						</LazyLoad>
					</a>
				</Grid>
			);
		});
	}
	return <></>;
});

export default CardList;
