import React from "react";

import Navbar from "../components/Navbar";

import { Container, Grid } from "@material-ui/core/";
import { makeStyles } from "@material-ui/core/styles";
import Font from "../assets/font";

const useStyles = makeStyles(theme => ({
	container: {
		padding: 0
	},
	txt: {
		color: "#d5b26e",
		fontSize: "1.3rem",
		fontFamily: Font.default,
		margin: "6% 3%",
		textDecoration: "none"
	}
}));

export default function CustomDecks() {
	const c = useStyles();
	return (
		<div>
			<Navbar />
			<Grid container justify="center">
				<Grid item container md={12} justify="center">
					<a
						href="https://ouoggimg.s3-ap-southeast-1.amazonaws.com/V3_0_9_2_en_us.rar"
						className={c.txt}
					>
						[2020/03/18] V3 英文版修正檔 (en_us) 版本0_9_2
					</a>
				</Grid>
				<Grid item container md={12} justify="center">
					<a
						href="https://ouoggimg.s3-ap-southeast-1.amazonaws.com/V3_0_9_2_ja_jp.rar"
						className={c.txt}
					>
						[2020/03/18] V3 日文版修正檔 (ja_jp) 版本0_9_2
					</a>
				</Grid>
			</Grid>
		</div>
	);
}
