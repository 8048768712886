import React, { useState } from "react";
import { Badge, Grid, makeStyles } from "@material-ui/core";

import {
	GiChewedSkull,
	GiHealthIncrease,
	GiMuscleUp,
	GiSacrificialDagger,
	GiIceSpellCast
} from "react-icons/gi";
import Global from "../assets/global";

const useStyles = makeStyles(theme => ({
	cardSlot: {
		background: "#3E3B3E"
	},
	iconAndTextDiv: {
		margin: "20px",
		textAlign: "center",
	},
	preview: {
		position: "absolute",
		height: "300px",
		zIndex: "100"
	},
	icon: {
		fontSize: "30px"
	},
	badgedmg: {
		color: "#d5b26e",
		fontSize: "14px",
		background: "#1a1a1a",
		marginTop: "-10px",
		width: "60px",
		fontFamily: "'Squada One', cursive"
	}
}));

const QuickIcon = ({ item }) => {
	const c = useStyles();
	// const mana = item[0];
	// const code = item[1];
	// const region = item[2];
	const type = item[3];
	const attack = item[4];
	const hp = item[5];
	const target = item[6];
	const remarks = item[7];

	let badgeContent = "";
	let icon;

	switch (type) {
		case "DAMAGE":
			icon = (
				<GiSacrificialDagger
					className={c.icon}
					style={{ color: "#ef9a9a" }}
				/>
			);
			badgeContent = attack;
			break;
		case "CONTROL":
			icon = (
				<GiIceSpellCast
					className={c.icon}
					style={{ color: "#5d99c6" }}
				/>
			);
			badgeContent = remarks;
			break;
		case "PROTECT":
			icon = (
				<GiMuscleUp className={c.icon} style={{ color: "#e6ee9c" }} />
			);
			badgeContent =
				(attack > 0 ? "+" : "") +
				attack +
				"/" +
				(hp > 0 ? "+" : "") +
				hp;
			break;
		case "KILL":
			icon = (
				<GiChewedSkull
					className={c.icon}
					style={{ color: "#c2185b" }}
				/>
			);
			badgeContent = target;
			break;
		case "HEAL":
			icon = (
				<GiHealthIncrease
					className={c.icon}
					style={{ color: "#4caf50" }}
				/>
			);
			badgeContent = "+" + hp;
			break;
		default:
			icon = <></>;
	}

	return (
		<Badge badgeContent={badgeContent} classes={{ badge: c.badgedmg }}>
			{icon}
		</Badge>
	);
};

export default function AnalysisCard(props) {
	const c = useStyles();
	let res = [];

	const IconAndText = ({ item }) => {
		const [showPreview, setShowPreview] = useState(false);

		const Preview = () => {
			return (
				<img
					alt=""
					src={
						"https://ouoggimg.s3-ap-southeast-1.amazonaws.com/img/cards/" +
						item[1] +
						Global.EXT
					}
					className={c.preview}
				/>
			);
		};
		return (
			<Grid
				item
				md={2}
				className={c.iconAndTextDiv}
				onClick={() => setShowPreview(true)}
				onMouseEnter={() => setShowPreview(true)}
				onMouseLeave={() => setShowPreview(false)}
			>
				<QuickIcon item={item} />
				{/* <QuickIconText item={item} /> */}
				{showPreview && <Preview />}
			</Grid>
		);
	};

	props.dataArr.forEach(item => {
		// CODE + TYPE + ATTACK + HP + remarks as key
		let key = "";
		item.forEach(x => {
			key += x;
		});
		res.push(
			<div key={key}>
				<IconAndText item={item} />
			</div>
		);
	});

	return <>{res}</>;
}
