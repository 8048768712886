const setCookie = (cookieKey, cookieValue, expirationDays) => {
	let expiryDate = "";

	if (expirationDays) {
		const date = new Date();

		date.setTime(
			`${date.getTime()}${expirationDays || 30 * 24 * 60 * 60 * 1000}`
		);

		expiryDate = `; expiryDate=" ${date.toUTCString()}`;
	}

	document.cookie = `${cookieKey}=${cookieValue || ""}${expiryDate}; path=/`;
};

const getCookie = cookieKey => {
	let cookieName = `${cookieKey}=`;

	let cookieArray = document.cookie.split(";");

	for (let cookie of cookieArray) {
		while (cookie.charAt(0) === " ") {
			cookie = cookie.substring(1, cookie.length);
		}

		if (cookie.indexOf(cookieName) === 0) {
			return cookie.substring(cookieName.length, cookie.length);
		}
	}
};

const removeCookie = cookieKey => {
	setCookie(cookieKey, "", 0);
};

const cleanCookie = () => {
	return document.cookie.split(";").reduce(function(acc, cookie) {
		const eqPos = cookie.indexOf("=");
		const cleanCookie = `${cookie.substr(
			0,
			eqPos
		)}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;`;
		return `${acc}${cleanCookie}`;
	}, "");
};

const Auth = {
	setCookie: setCookie,
	getCookie: getCookie,
	removeCookie: removeCookie,
	cleanCookie: cleanCookie
};

export default Auth;
