const insertUser = dbobj => {
	fetch("https://backouogg.herokuapp.com/insertuser", {
		headers: { "Content-Type": "application/json; charset=utf-8" },
		method: "POST",
		body: JSON.stringify(dbobj)
	});
};

const checkIfUserExist = profileId => {
	fetch("https://backouogg.herokuapp.com/checkuser", {
		headers: { "Content-Type": "application/json; charset=utf-8" },
		method: "POST",
		body: JSON.stringify({ profileId: profileId })
	})
		.then(response => response.json())
		.then(body => body);
};

const Mongo = {
	insertUser: insertUser,
	checkIfUserExist: checkIfUserExist
};

export default Mongo;
