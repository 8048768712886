import React, { useState } from "react";

import { makeStyles, Grid, GridListTile } from "@material-ui/core/";
import Font from "../assets/font";
import Color from "../assets/color";
import Global from "../assets/global";

const BAR_HEIGHT = "50px";
const BAR_WIDTH = "300px";
const RADIUS = "8px";

const useStyles = makeStyles(theme => ({
	previewInfo: {
		display: "inline",
		margin: "1%"
	},
	previewContainer: {
		display: "inline-flex",
		alignItems: "center",
		height: BAR_HEIGHT,
		width: "80%",
		marginBottom: "3%",

		color: "#fff",
		textAlign: "center",
		fontFamily: Font.default
	},
	previewImg: {
		width: "100%"
	},
	previewTxt: {
		fontSize: "1rem",
		fontWeight: "900",
		margin: "0 25px",
		color: "#fff",
		fontFamily: Font.checkDeck,
	},
	imgPreview: {
		maxHeight: BAR_WIDTH,
		position: "absolute",
		marginTop: "-200px",
		marginLeft: "200px",
		zIndex: "100"
	},
	previewCost: {
		fontSize: "18px"
	},
	manaCircle: {
		padding: "0 20px",
		height: "100%",
		fontFamily: Font.checkDeck,
		fontSize: "1.1rem",
		fontWeight: "900",

		display: "inline-flex",
		alignItems: "center",
		textAlign: "center",

		backgroundRepeat: "no-repeat",
		backgroundPosition: "center",
		backgroundSize: "30px 30px"
	},
	countCircle: {
		height: BAR_HEIGHT,
		width: "30px",
		color: "#FFF",
		display: "inline-flex",
		alignItems: "center",
		position: "absolute",
		textAlign: "center",
		paddingLeft: "15px"
	},
	countNum: {
		fontSize: "1.1rem",
		fontFamily: Font.checkDeck,
		fontWeight: "500"
	}
}));

const prefix = code =>
	"url('https://ouoggimg.s3-ap-southeast-1.amazonaws.com/img/scards/" +
	code +
	Global.EXT +
	"')";

// Props:
// item: Full Object from JSON {}
export default function DeckImgThumbnail({ item, count }) {
	const c = useStyles();
	const [showImg, setShowImg] = useState(false);

	const IMG_URL = prefix(item["cardCode"]);
	const REGION_COLOR = Color.region[item["regionRef"]];

	const ImagePreview = () => {
		if (showImg)
			return (
				<img
					src={
						"https://ouoggimg.s3-ap-southeast-1.amazonaws.com/img/scards/" +
						item["cardCode"] +
						Global.EXT
					}
					className={c.imgPreview}
				/>
			);
		else return <></>;
	};

	const bgImage = () => {
		let dir = "to left";
		let from = "transparent";
		let to = Color.region[item["regionRef"]] + " 95%";
		let bgsize;
		let bgposx;
		let bgposy;

		if (item["type"] === "Spell") {
			bgsize = "330px";
			bgposy = "-90px";
			bgposx = "40px";
		} else if (item["supertype"] === "Champion") {
			bgsize = "300px";
			bgposy = "-90px";
			bgposx = "40px";
		} else {
			bgsize = "400px";
			bgposy = "-140px";
			bgposx = "40px";
		}

		return {
			backgroundImage: `linear-gradient(${dir}, ${from}, ${to}), ${IMG_URL}`,
			backgroundSize: bgsize,
			backgroundPositionY: bgposy,
			backgroundPositionX: bgposx
		};
	};

	return (
		<Grid item xs={12} className={c.previewInfo}>
			<ImagePreview />
			<div
				className={c.previewContainer}
				style={bgImage()}
				onMouseOver={() => setShowImg(true)}
				onMouseLeave={() => setShowImg(false)}
				onClick={() => setShowImg(true)}
			>
				<div
					className={c.manaCircle}
					style={{ background: REGION_COLOR }}
				>
					{item["cost"]}
				</div>
				<p className={c.previewTxt}>{item["name"]}</p>
			</div>
			<div className={c.countCircle} style={{ background: REGION_COLOR }}>
				<h3 className={c.countNum}>x{count}</h3>
			</div>
		</Grid>
	);
}
