const Color = {
	primary: {
		bg: "#242124",
		fg: "#d5b26e"
	},
	dark: {
		bg: "#242124",
		fg: "#d5b26e"
	},

	light: {
		bg: "#2d292d",
		fg: "#d5b26e"
	},

	region: {
		Demacia: "#a7a7a3",
		Noxus: "#AE4A40",
		Freljord: "#5BACD0",
		Ionia: "#BC6583",
		PiltoverZaun: "#D59569",
		ShadowIsles: "#4AA897"
	}
};

export default Color;
