import React, { useRef } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core/";
import Navbar from "../components/Navbar";
import FilterButtonGroup from "../components/FilterButtonGroup";
import CardList from "../components/CardList";

const useStyles = makeStyles(theme => ({
	cardsGrid: {
		padding: "0"
	},
	cardsDisplayZone: {
		justifyContent: "center",
		marginTop: "1%"
	}
}));

export default function Cards() {
	const c = useStyles();
	const cardListRef = useRef();

	return (
		<>
			<Navbar />
			<Grid container className={c.cardsGrid}>
				<FilterButtonGroup cardListRef={cardListRef} />

				<Grid item container md={10} className={c.cardsDisplayZone}>
					<CardList ref={cardListRef} />
				</Grid>
			</Grid>
		</>
	);
}
