import React, { useRef, useEffect } from "react";

import { Grid, makeStyles } from "@material-ui/core";

import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import GoogleLogin from "react-google-login";

import { FaFacebookF, FaGoogle } from "react-icons/fa";
import Color from "../assets/color";
import Font from "../assets/font";

import Navbar from "../components/Navbar";
import Auth from "./Auth";
import Mongo from "./Mongo";

const useStyles = makeStyles(theme => ({
	loginContainer: {
		background: Color.dark.bg + "",
		width: "60vh",
		padding: "3%",
		transition: "all 3s ease"
	},
	header: {
		color: Color.primary.fg,
		height: "50px",
		textAlign: "center"
	},
	icon: {
		margin: "0 20px",
		display: "inline",
		fontSize: "1.2rem"
	},
	btnLogin: {
		width: "300px",
		background: Color.primary.bg,
		color: Color.primary.fg,
		border: "none",
		cursor: "pointer",

		padding: "2% 0",
		margin: "5%",
		transition: "all 0.5s ease",

		"&:hover": {
			background: Color.light.bg,
			transition: "all 0.5s ease"
		}
	},
	btnText: {
		fontFamily: Font.default,
		fontSize: "0.9rem"
	},
	grid: {}
}));

export default function LoginForm(props) {
	const setLoggedIn = props.setLoggedIn;
	const setLoginModalOpen = props.setLoginModalOpen;
	const c = useStyles();

	const insertDBAndUpdateState = dbobj => {
		Mongo.insertUser(dbobj);
		setLoggedIn(true);
		setLoginModalOpen(false);
	};

	const responseFacebook = res => {
		if (!res || res["status"] === "unknown") return;

		let dbobj = new Object();

		dbobj["profileType"] = "facebook";
		dbobj["profileId"] = res["userID"];
		dbobj["profileName"] = res["name"];
		dbobj["profileEmail"] = res["email"];
		dbobj["profileImgUrl"] = res["picture"]["data"]["url"];
		dbobj["profileData"] = res;

		Auth.setCookie("_userimg", res["picture"]["data"]["url"], "1");
		insertDBAndUpdateState(dbobj);
		window.location.reload(true);
	};
	const responseGoogle = res => {
		if (!res || res["error"]) return;

		let dbobj = new Object();

		dbobj["profileType"] = "google";
		dbobj["profileId"] = res["profileObj"]["googleId"];
		dbobj["profileName"] = res["profileObj"]["name"];
		dbobj["profileEmail"] = res["profileObj"]["email"];
		dbobj["profileImgUrl"] = res["profileObj"]["imageUrl"];
		dbobj["profileData"] = res;

		Auth.setCookie("_userimg", res["profileObj"]["imageUrl"], "1");

		console.log(Mongo.checkIfUserExist(dbobj["profileId"]));
		insertDBAndUpdateState(dbobj);
		window.location.reload(true);
	};
	return (
		<>
			<Grid container justify="center" className={c.loginContainer}>
				<Grid className={c.grid} item md={6} container justify="center">
					<FacebookLogin
						appId="1008301459570329"
						fields="name,email,picture"
						callback={responseFacebook}
						render={renderProps => (
							<button
								className={c.btnLogin}
								onClick={renderProps.onClick}
							>
								<FaFacebookF className={c.icon} />
								<div className={c.btnText}>以Facebook登入</div>
							</button>
						)}
					/>
				</Grid>
				<Grid className={c.grid} item md={6} container justify="center">
					<GoogleLogin
						clientId="622413487852-99a5rpc4tuof5hmsjbdt73mk1g3n9bki.apps.googleusercontent.com"
						buttonText="LOGIN WITH GOOGLE"
						onSuccess={responseGoogle}
						onFailure={responseGoogle}
						render={renderProps => (
							<button
								className={c.btnLogin}
								onClick={renderProps.onClick}
							>
								<FaGoogle className={c.icon} />
								<div className={c.btnText}>以Google登入</div>
							</button>
						)}
					/>
				</Grid>
			</Grid>
		</>
	);
}
