import React, { forwardRef, useImperativeHandle, useState } from "react";

import { Grid } from "@material-ui/core/";
import CustomThumbnail from "./CustomThumbnail";
import Helper from "./Helper";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
	pickedContainer:{
		width: "35vh",
	},
	item: {
		margin: "10px",
		cursor: "pointer",
		color: "#fff"
	}
});

//////////////////////////////////////////////////////////
// 					Deck rule:							//
// 1. At most 6 champions			(championCount)		//
// 2. At most 2 regions				(regionCount)		//
// 3. At most 40 cards				(codeCountMap.size)	//
// 4. At most 3 copies each card	(codeCountMap)		//
//////////////////////////////////////////////////////////
const CustomPickedCard = forwardRef((props, ref) => {
	const c = useStyles();
	const customCardListRef = props.customCardListRef;
	const [codeCountMap, setCodeCountMap] = useState(new Map());
	const [championCount, setChampionCount] = useState(0);
	const [regionCount, setRegionCount] = useState(0);
	const [pickedCard, setPickedCard] = useState([]);

	const getCount = code => {
		return codeCountMap.get(code);
	};

	const getMapSize = () => {
		return Array.from(codeCountMap.values()).reduce((sum, item) => {
			return sum + item;
		}, 0);
	};

	const addCard = data => {
		const code = data["cardCode"];
		const count = codeCountMap.get(data["cardCode"]);
		let clone = JSON.parse(JSON.stringify(pickedCard));

		if (!code) return "卡牌數據錯誤";
		if (getMapSize() === 40) return "牌組數目已滿";
		if (count === 3) return "每張卡牌最多只能增加三張";
		if (Helper.getCardType(code) === "CHAMPION") {
			if (championCount === 6) return "最多只能新增6張英雄牌";
			setChampionCount(championCount + 1);
		}

		// Only add card stats on first insert
		if (count == null) clone.push(data);

		codeCountMap.set(code, count ? count + 1 : 1);
		setPickedCard(clone);

		return "OK";
	};

	const removeCard = data => {
		const code = data["cardCode"];
		const count = codeCountMap.get(data["cardCode"]);
		let clonePickedCard = JSON.parse(JSON.stringify(pickedCard));
		let cloneCountMap = new Map(codeCountMap);

		if (Helper.getCardType(code) === "CHAMPION")
			setChampionCount(championCount - 1);

		if (count === 1) {
			// Clear PickedCard && codeCountMap
			clonePickedCard = clonePickedCard.filter((val, i) => {
				return val["cardCode"] != code;
			});
			cloneCountMap.delete(code);
		} else if (count > 1) cloneCountMap.set(code, count - 1);

		setPickedCard(clonePickedCard);
		setCodeCountMap(cloneCountMap);
	};

	useImperativeHandle(ref, () => ({ addCard }));

	return (
		<>
			<div className={c.pickedContainer}>
				{pickedCard &&
					Object.keys(pickedCard).map(i => {
						return (
							<CustomThumbnail
								removeCard={removeCard}
								item={pickedCard[i]}
								count={getCount(pickedCard[i]["cardCode"])}
							/>
						);
					})}
			</div>
		</>
	);
});

export default CustomPickedCard;
