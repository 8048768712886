import React, { useState } from "react";

import { Grid, Modal, Backdrop, Fade } from "@material-ui/core/";
import { makeStyles } from "@material-ui/core/styles";

import Color from "../assets/color";
import Font from "../assets/font";

const useStyle = makeStyles({
	filterBg: {
		borderBottom: "3px solid " + Color.primary.fg,
		color: Color.primary.fg,

		height: "80px",
		display: "inline-flex",
		alignItems: "center"
	},
	filterBtn: {
		color: Color.primary.fg,
		fontSize: "0.8rem",
		height: "80px",
		border: "none",
		cursor: "pointer",
		display: "inline-flex",
		alignItems: "center",
		"&:hover": {
			borderBottom: "4px solid #242124"
		}
	},
	optionModal: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		height: "30vh"
	},
	dropDown: {
		left: "0",
		top: "155px",
		height: "50px",
		width: "100%",
		position: "absolute",
		background: Color.dark.bg,
		color: Color.dark.fg,
		minWidth: "200px",
		boxShadow: "2px 1px 2px rgba(0, 0, 0, 1)",

		fontFamily: Font.default,
		fontSize: "16px",
		fontWeight: "500"
	}
});

const CustomFilter = props => {
	const customPickedCardRef = props.customPickedCardRef;
	const arr = ["地區", "法力", "類別", "技能"];
	const c = useStyle();

	const FilterOption = props => {
		const c = useStyle();
		const [selectedOptions, setSelectedOptions] = useState();
		const [showDropDown, setShowDropDown] = useState(false);

		const Dropdown = () => {
			return (
				<Grid container className={c.dropDown} justify="center">
					{props.title}
				</Grid>
			);
		};

		return (
			<>
				<Grid
					item
					md={2}
					container
					justify="center"
					className={c.filterBtn}
					onClick={() => customPickedCardRef.current.hello()}
					onMouseEnter={() => setShowDropDown(true)}
					onMouseLeave={() => setShowDropDown(false)}
				>
					{props.title}
					{showDropDown && <Dropdown />}
				</Grid>
			</>
		);
	};

	return (
		<>
			<Grid
				className={c.filterBg}
				container
				item
				md={12}
				justify="center"
			>
				{arr.map(item => {
					return <FilterOption title={item} />;
				})}
			</Grid>
		</>
	);
};

export default CustomFilter;
