import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
	test: {
		background: "transparent",
		width: "100px",
		height: "100px"
	}
}));
export default function CardListPlacehodler() {
	const c = useStyles();
	return (
		<>
			<img
				alt=""
				src={
					"https://ouoggimg.s3-ap-southeast-1.amazonaws.com/img/porolazy.png"
				}
				className={c.test}
			></img>
		</>
	);
}
