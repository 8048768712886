const canUseWebP = () => {
	var elem = document.createElement("canvas");

	if (!!(elem.getContext && elem.getContext("2d"))) {
		// was able or not to get WebP representation
		return elem.toDataURL("image/webp").indexOf("data:image/webp") == 0;
	}

	// very old browser like IE 8, canvas not supported
	return false;
};

const GetEXT = () => {
    return canUseWebP()? ".webp": ".png";
};

const Global = {
	IMG_PREFIX: "https://ouoggimg.s3-ap-southeast-1.amazonaws.com/img/",
	EXT: GetEXT(),
};

export default Global;
