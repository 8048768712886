import React, { useState } from "react";
import { Grid, makeStyles } from "@material-ui/core/";

import Navbar from "../components/Navbar";
import DeckCheckInput from "../components/DeckCheckInput";
import DeckChart from "../components/DeckChart";
import DeckImgThumbnail from "../components/DeckImgThumbnail";
import Helper from "../components/Helper";

import Color from "../assets/color";
import Font from "../assets/font";

const { DeckEncoder } = require("runeterra");

const useStyles = makeStyles(theme => ({
	deckImg: {
		width: "100%"
	},
	deckChart: {
		height: "30vh"
	},
	deckSubtitle: {
		textAlign: "center",
		color: Color.primary.fg,
		fontFamily: Font.default,
		fontSize: "1rem"
	},
	cardTypeContainer: {
		width: "30%"
	}
}));

export default function CheckDecks() {
	const c = useStyles();
	const [cardDisplay, setCardDisplay] = useState();

	const getDecode = code => {
		let res = DeckEncoder.decode(code);

		res.sort((a, b) => {
			return (
				Helper.getObj(a["code"])["cost"] -
				Helper.getObj(b["code"])["cost"]
			);
		});

		setCardDisplay(res);
	};

	const DeckImgGroup = () => {
		if (!cardDisplay) return <></>;

		let champion = [];
		let spell = [];
		let follower = [];

		// Logic of classifying the type of cards
		cardDisplay.forEach(item => {
			let tmpObj = Helper.getObj(item["code"]);
			switch (Helper.getCardType(item["code"])) {
				case "SPELL":
					spell.push(tmpObj);
					break;
				case "CHAMPION":
					champion.push(tmpObj);
					break;
				case "FOLLOWER":
					follower.push(tmpObj);
					break;
			}
		});

		const getCount = code => {
			let res = cardDisplay.find(item => {
				return item["code"] === code;
			});
			return res["count"];
		};

		// FORMAT: [DisplayName, classifiedCardArr]
		return (
			<>
				{[
					["英雄", champion],
					["法術", spell],
					["隨從", follower]
				].map(item => {
					return (
						<Grid
							container
							className={c.cardTypeContainer}
							justify="center"
							item
							xs={12}
							md={4}
						>
							<Grid
								item
								xs={12}
								md={12}
								className={c.deckSubtitle}
							>
								{item[0]}
							</Grid>
							{item[1].map(item => {
								return (
									<DeckImgThumbnail
										key={item["cardCode"]}
										item={item}
										count={getCount(item["cardCode"])}
									/>
								);
							})}
						</Grid>
					);
				})}
			</>
		);
	};

	return (
		<>
			<Navbar />
			<Grid container justify="center">
				<DeckCheckInput getDecode={getDecode} />
				<Grid item lg={3} md={3} xs={12} className={c.deckChart}>
					<DeckChart cardDisplay={cardDisplay} />
				</Grid>
				<Grid item lg={8} md={12} xs={12} container justify="center">
					<DeckImgGroup />
				</Grid>
			</Grid>
		</>
	);
}
