import React, { useState } from "react";

import {
	Container,
	Grid,
	Button,
	makeStyles,
	Typography
} from "@material-ui/core/";

import Color from "../assets/color";
import Font from "../assets/font";
import MasterFilData from "../assets/masterFilter.json";

import Navbar from "../components/Navbar";
import AnalysisCardGroup from "../components/AnalysisCardGroup";

const useStyles = makeStyles(theme => ({
	titleGuide: {
		color: Color.primary.fg,
		fontSize: "30px",
		fontFamily: Font.default,
		textAlign: "center",
		padding: "3%"
	},
	btnUnChecked: {
		color: "#fff",
		width: "100%",
		visibility: "visible",
		opacity: "0.5",
		justifyContent: "center",
		textAlign: "center",
		fontFamily: "'Noto Sans TC', 'Roboto', sans-serif"
	},
	btnChecked: {
		color: "#fff",
		width: "100%",
		visibility: "visible",
		opacity: "1",
		justifyContent: "center",
		textAlign: "center",
		fontFamily: "'Noto Sans TC', 'Roboto', sans-serif"
	},
	btnImg: {
		height: "30px",
		marginLeft: "5%"
	},
	btnText: {
		display: "inline",
		marginLeft: "2%"
	},
	tableContainer: {
		marginTop: "3%"
	},
	regionBadge: {
		width: "50px",
		height: "auto"
	}
}));

const PREFIX = "https://ouoggimg.s3-ap-southeast-1.amazonaws.com/img/";

export default function Analysis() {
	const c = useStyles();
	const [selectedRegion, setSelectedRegion] = useState(new Set());

	const RegionButton = region => {
		const c = useStyles();

		const toggleRegionBtn = () => {
			let clone = new Set(selectedRegion);
			if (selectedRegion.has(region["name"]))
				clone.delete(region["name"]);
			else clone.add(region["name"]);
			setSelectedRegion(clone);
		};

		return (
			<Grid item xs={4} md={2}>
				<Button
					className={
						selectedRegion.has(region["name"])
							? c.btnChecked
							: c.btnUnChecked
					}
					onClick={() => toggleRegionBtn()}
				>
					<img
						src={PREFIX + region["iconpath"]}
						className={c.btnImg}
						alt=""
					/>
					<div className={c.btnText}>{region["zhname"]}</div>
				</Button>
			</Grid>
		);
	};

	const RegionButtonGroup = () => {
		return MasterFilData["regionRef"].map(region => {
			return <RegionButton key={"btn_" + region["name"]} {...region} />;
		});
	};

	return (
		<div>
			<Navbar />
			<Container maxWidth="xl">
				<Typography className={c.titleGuide}>選取對手地區</Typography>
				<Grid container spacing={2} justify="center">
					<RegionButtonGroup />
					<AnalysisCardGroup selectedRegion={selectedRegion} />
				</Grid>
			</Container>
		</div>
	);
}
