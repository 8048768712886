import React, { useRef } from "react";
import {
	Button,
	Container,
	Grid,
	makeStyles,
	TextField
} from "@material-ui/core/";
import Color from "../assets/color";
import Font from "../assets/font";

const useStyles = makeStyles(theme => ({
	input: {
		border: "none",
		background: Color.dark.bg,
		textAlign: "center",
		margin: "3% 0",
		padding: "1%",
		width: "100%",

		color: "#A27F3B",
		fontSize: "2rem",
		fontFamily: "'Squada One', cursive",
		fontWeight: "100",
		letterSpacing: "2px",

		"&:hover": {
			color: Color.primary.fg,
			transition: "1s"
		},
		"&:focus": {
			transition: "1s",
			border: "none"
		}
	},
	inputTitle: {
		fontSize: "1rem",
		color: Color.primary.fg,
		textAlign: "center",
		marginTop: "3%"
	},
	btn: {
		minWidth: "200px",
		padding: "1%",

		color: Color.primary.fg,
		fontSize: "1.0rem",
		fontFamily: Font.default,

		border: "double 1px " + Color.primary.fg,

		marginBottom: "5%"
	}
}));

export default function DeckCheckInput(props) {
	const c = useStyles();
	const inputRef = useRef();

	const makeCode = e => {
		const inputCode = inputRef.current.value;
		try {
			props.getDecode(inputCode);
		} catch (err) {
			inputRef.current.value = "牌組代碼不正確"
		}
	};

	return (
		<>
			<Grid item md={10} xs={12} container justify="center">
				<textarea
					className={c.input}
					ref={inputRef}
					spellCheck="false"
				></textarea>
				<Button className={c.btn} onClick={() => makeCode()}>查牌組代碼</Button>
			</Grid>
		</>
	);
}
