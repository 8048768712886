import React, { useRef } from "react";

import Navbar from "../components/Navbar";
import CustomFilter from "../components/CustomFilter";
import CustomPickedCard from "../components/CustomPickedCard";
import CustomCardList from "../components/CustomCardList";

import { Grid } from "@material-ui/core/";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
	container: {
		padding: 0
	}
}));

export default function CustomDecks() {
	const c = useStyles();

	const customPickedCardRef = useRef();
	const customCardListRef = useRef();

	// [------------]
	// [	A(C)	]
	// [------------]
	// [ B(C) | C(B)]
	// [------------]


	return (
		<div>
			<Navbar />
			<Grid container justify="center">
				<CustomFilter customCardListRef={customCardListRef} />
				<CustomPickedCard
					ref={customPickedCardRef}
					customCardListRef={customCardListRef}
				/>
				<CustomCardList
					ref={customCardListRef}
					customPickedCardRef={customPickedCardRef}
				/>
			</Grid>
		</div>
	);
}
