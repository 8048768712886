import React from "react";
import { Grid, makeStyles, Typography } from "@material-ui/core";

import ManaData from "../assets/analysisData.json";
import AnalysisCard from "../components/AnalysisCard";

const useStyles = makeStyles(theme => ({
	cardContainer: {
		background: "#242124",
		boxShadow: "0 1px 2px rgba(0, 0, 0, 1)",
		margin: "5px"
	},
	cardMana: {
		textAlign: "center"
	},
	cardManaText: {
		fontSize: "25px",
		color: "#d5b26e",

		"@media (max-width: 600px)": {
			marginBottom: "30px"
		}
	},
	cardSlot: {
		background: ""
	}
}));

export default function AnalysisCardGroup(props) {
	const c = useStyles();
	const data = ManaData["data"];
	const res = [];

	for (let i = 0; i < 10; i++) {
		const manaRegionFiltered = data
			.filter(item => {
				return item[0] === String(i);
			})
			.filter(item => {
				return props.selectedRegion.has(item[2]);
			});

		const title = (
			<Grid key={"mana_" + i} item md={2} className={c.cardMana}>
				<Typography className={c.cardManaText}>{i}</Typography>
			</Grid>
		);
		const content = (
			<Grid key={"manaFiltered_" + i} container item md={10}>
				<AnalysisCard mana={i} dataArr={manaRegionFiltered} />
			</Grid>
		);

		res.push(
			<Grid
				container
				justify="center"
				className={c.cardContainer}
				item
				md={5}
				key={"manaCard_" + i}
			>
				{title}
				{content}
			</Grid>
		);
	}

	return res;
}
