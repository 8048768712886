import React from "react";
import SetData from "../assets/set1-en_us_v_1_0_0.json";

function getObj(code) {
	let res;
	Object.keys(SetData).forEach(key => {
		if (SetData[key]["cardCode"] === code) {
			res = SetData[key];
		}
	});

	return res;
}

function getSetData(){
	return SetData;
}

function getCardType(code) {
	let res = getObj(code);
	if (res["type"] === "Spell") return "SPELL";
	else if (res["supertype"] === "Champion") return "CHAMPION";
	else return "FOLLOWER";
}

function getCount(code) {
	let res = getObj(code);
	console.log(res);

	return res["count"];
}

function getCollectible() {
	let res = [];
	Object.keys(SetData).forEach(key => {
		if (SetData[key]["collectible"] == true) res.push(SetData[key]);
	});
	return res;
}

const Helper = { getObj, getCardType, getCount, getCollectible, getSetData };

export default Helper;
