import React, { forwardRef, useState, useImperativeHandle } from "react";
import LazyLoad from "react-lazyload";
import { Grid, makeStyles } from "@material-ui/core";

import Global from "../assets/global";
import SmallData from "../assets/small.json";
import Helper from "../components/Helper";
import CardListPlaceholder from "../components/CardListPlaceholder";

const useStyles = makeStyles({
	item: {
		margin: "10px",
		cursor: "pointer",
		color: "#fff"
	}
});

const CustomCardList = forwardRef((props, ref) => {
	const customPickedCardRef = props.customPickedCardRef;
	const collectibleCard = Helper.getCollectible();
	collectibleCard.sort((a, b) => {
		return a["cost"] - b["cost"];
	});

	collectibleCard.forEach(item => {
		if (item["cardCode"] == null) console.log(item["cardCode"]);
	});

	let applyStyle = {};
	const c = useStyles();
	const [cardList, setCardList] = useState(collectibleCard);

	useImperativeHandle(ref, () => ({
		setCardList
	}));

	const setPickedCardWrapper = data => {
		const res = customPickedCardRef.current.addCard(data);
		if (res != "OK") alert(res);
	};

	return (
		<>
			<Grid item container md={8} justify="center">
				{cardList.map(item => {
					return (
						<div
							className={c.item}
							onClick={() => setPickedCardWrapper(item)}
						>
							<LazyLoad placeholder={<CardListPlaceholder />}>
								<img
									src={
										Global.IMG_PREFIX +
										"cards/" +
										item["cardCode"] +
										Global.EXT
									}
									style={{ width: "150px" }}
								/>
							</LazyLoad>
						</div>
					);
				})}
			</Grid>
		</>
	);
});

export default CustomCardList;
