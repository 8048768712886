import React, {
	useState,
	useImperativeHandle,
	forwardRef,
	useEffect
} from "react";

import { makeStyles } from "@material-ui/core/styles";
import {
	Hidden,
	Grid,
	Link,
	Modal,
	Fade,
	Backdrop,
	Divider
} from "@material-ui/core/";
import MenuIcon from "@material-ui/icons/Menu";

import Color from "../assets/color";
import Font from "../assets/font";

import Auth from "./Auth";
import LoginForm from "./LoginForm";

const useStyles = makeStyles(theme => ({
	nav: {
		color: Color.dark.fg,
		background: Color.dark.bg,
		textAlign: "center",
		fontFamily: Font.default,
		paddingTop: "25px",
		top: "0",
		position: "sticky",
		zIndex: "500",
		height: "50px",
		boxShadow: " 0 3px 8px -2px #000"
	},
	navTitle: {
		color: "inherit",
		textDecoration: "none",
		fontWeight: 100
	},
	navMenu: {
		fontSize: "16px"
	},
	navMenuItems: {
		textDecoration: "none",
		fontWeight: 500,
		letterSpacing: "3px",
		position: "relative",
		color: Color.primary.fg,

		"&::before": {
			content: "''",
			position: "absolute",
			width: "100%",
			height: "2px",
			bottom: "-10px",
			left: "0",
			backgroundColor: "#000",
			visibility: "hidden",
			webkitTransform: "scaleX(0)",
			transform: "scaleX(0)",
			webkitTransition: "all 0.5s ease-in-out 0s",
			transition: "all 0.5s ease-in-out 0s"
		},
		"&:hover": {
			color: Color.primary.fg,
			"&::before": {
				visibility: "visible",
				background: Color.primary.fg,
				webkitTransform: "scaleX(1)",
				transform: "scaleX(1)"
			}
		}
	},
	selected: {
		borderBottom: "solid 1px " + Color.primary.fg
	},

	mNav: {
		fontFamily: Font.default,
		background: Color.dark.bg,
		color: Color.dark.fg,
		boxShadow: "0 4px 12px -1px #000",
		textAlign: "center",
		padding: "3% 0",

		position: "sticky",
		top: "0",
		width: "100%",
		zIndex: "200"
	},
	mTitle: {
		fontSize: "30px",
		color: Color.dark.fg,
		fontFamily: "'Open Sans'",
		fontWeight: "100",
		letterSpacing: "5px",
		"&:hover": {
			background: "inherit",
			textDecoration: "none"
		}
	},
	mMenuIcon: {
		display: "fixed",
		right: 0,
		position: "absolute",
		margin: "2% 8%",
		color: Color.dark.fg
	},
	mMenuContainer: {
		padding: "40px"
	},
	loginZone: {
		width: "200px",
		minWidth: "50px",
		textDecoration: "none",
		fontFamily: Font.default,

		position: "absolute",
		right: "0",
		top: "0",
		height: "50px",
		paddingTop: "25px",

		transition: "all ease-in-out 0.7s",
		webkitTransition: "all ease-in-out 0.7s",
		cursor: "pointer",

		"&:hover": {
			background: Color.light.bg
		}
	},
	modal: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center"
	},
	profileImg: {
		height: "50%",
		borderRadius: "60px",
		background: "transparent",
		margin: "auto"
	},
	profileSection: {
		top: "75px",
		right: "0",
		position: "absolute",
		background: Color.dark.bg,
		color: Color.dark.fg,
		minWidth: "200px",
		boxShadow: "2px 1px 2px rgba(0, 0, 0, 1)",

		fontFamily: Font.default,
		fontSize: "16px",
		fontWeight: "500"
	}
}));

const Navbar = forwardRef((props, ref) => {
	const c = useStyles();
	const [showMenu, setShowMenu] = useState(false);
	const [loggedIn, setLoggedIn] = useState();
	const [profileDisplay, setProfileDisplay] = useState(false);

	const [modalOpen, setLoginModalOpen] = useState(false);

	useEffect(() => {
		Auth.getCookie("_userimg") ? setLoggedIn(true) : setLoggedIn(false);
	});

	// Explicitly forwarded the state hook
	useImperativeHandle(ref, () => ({
		setLoggedIn
	}));

	const loginCall = () => {
		setLoginModalOpen(true);
		setProfileDisplay(false);
	};

	const logoutCall = () => {
		Auth.removeCookie("_userimg");
		setLoggedIn(false);
	};

	const MobileMenu = () => {
		return (
			<Grid
				container
				spacing={8}
				justify="center"
				className={c.mMenuContainer}
			>
				<Grid item xs={6}>
					<Link className={c.navMenuItems} href="/cards">
						卡牌圖鑑
					</Link>
				</Grid>
				<Grid item xs={6}>
					<Link className={c.navMenuItems} href="/checkdecks">
						查牌組代碼
					</Link>
				</Grid>
				{/* <Grid item xs={6}><Link className={c.navMenuItems} href="/customdecks">自選牌組</Link></Grid> */}
				<Grid item xs={6}>
					<Link className={c.navMenuItems} href="/analysis">
						對戰分析
					</Link>
				</Grid>
				<Grid item xs={6}>
					<a className={c.navMenuItems} href="/patches">
						漢化補丁
					</a>
				</Grid>
			</Grid>
		);
	};

	const ProfileSection = () => {
		return (
			<div className={c.profileSection}>
				<p onClick={() => logoutCall()}>登出</p>
				<Divider />
			</div>
		);
	};

	const MemberButton = () => {
		if (loggedIn)
			return (
				<div
					onMouseEnter={()=>setProfileDisplay(true)}
					onMouseLeave={() => setProfileDisplay(false)}
					className={c.loginZone}
				>
					<img
						className={c.profileImg}
						src={Auth.getCookie("_userimg")}
					/>
					{profileDisplay && <ProfileSection />}
				</div>
			);
		else
			return (
				<div onClick={() => loginCall()} className={c.loginZone}>
					登入
				</div>
			);
	};

	return (
		<>
			<Hidden smDown>
				<div className={c.nav}>
					<Grid container justify="center" className={c.navMenu}>
						<Grid item md={1}>
							<a className={c.navMenuItems} href="/cards">
								卡牌圖鑑
							</a>
						</Grid>
						<Grid item md={1}>
							<a className={c.navMenuItems} href="/checkdecks">
								查牌組代碼
							</a>
						</Grid>
						{/* <Grid item md={1}>
							<a className={c.navMenuItems} href="/customdecks">
								自選牌組
							</a>
						</Grid> */}
						<Grid item md={1}>
							<a className={c.navMenuItems} href="/analysis">
								對戰分析
							</a>
						</Grid>
						<Grid item md={1}>
							<a className={c.navMenuItems} href="/patches">
								漢化補丁
							</a>
						</Grid>
						{/* <MemberButton /> */}
					</Grid>
				</div>
			</Hidden>

			<Hidden mdUp>
				<div className={c.mNav}>
					<div
						className={c.mTitle}
						onClick={() => {
							setShowMenu(!showMenu);
						}}
					>
						ouo
					</div>
					{showMenu && <MobileMenu />}
				</div>
			</Hidden>
			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				className={c.modal}
				open={modalOpen}
				onClose={() => setLoginModalOpen(false)}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{ timeout: 700 }}
			>
				<Fade in={modalOpen}>
					<LoginForm
						setLoggedIn={setLoggedIn}
						setLoginModalOpen={setLoginModalOpen}
					/>
				</Fade>
			</Modal>
		</>
	);
});

export default Navbar;
