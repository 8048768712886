import React, { useState, useEffect } from "react";
import { Grid, Button, makeStyles } from "@material-ui/core/";
import Global from "../assets/global";
import Font from "../assets/font";

const useStyle = makeStyles(theme => ({
	btn: {
		color: "#fff",
		justifyContent: "left",
		width: "100%",
		visibility: "visible",
		opacity: "1",

		fontSize: "0.8rem"
	},
	btnImg: {
		height: "30px",
		marginLeft: "5%"
	},
	btnText: {
		marginLeft: "5%",
		display: "inline",
		fontFamily: Font.default
	}
}));

export default function FilterButton(props) {
	const [selected, setSelected] = useState(false);
	const c = useStyle();
	const type = props.type;
	const args = props.args;
	const pos = props.pos;
	const show = props.show;
	const typeStatus = props.typeStatus;
	const typeUpdateState = props.typeUpdateState;
	const applyFilter = props.applyFilter;

	if (!show) return <></>;
	let imgSrc = Global.IMG_PREFIX + args["iconpath"];
	let op = typeStatus[pos] ? 1 : 0.3;

	const toggleFilter = () => {
		typeStatus[pos] = !typeStatus[pos];
		setSelected(!selected);
		applyFilter();
	};

	return (
		<Grid className={c.leftNavBtn}>
			<Button
				onClick={() => toggleFilter(type)}
				style={{ opacity: op }}
				className={c.btn}
			>
				<img src={imgSrc} className={c.btnImg} alt="" />
				<div className={c.btnText}>{args["zhname"]}</div>
			</Button>
		</Grid>
	);
}
