import React from "react";
import { ResponsiveBar } from "@nivo/bar";
import Helper from "../components/Helper";
import Color from "../assets/color";
import Font from "../assets/font";

const makeManaData = cardDisplay => {
	let regionSet = new Set();
	let regionColor = [];
	let res = [];

	// Make region count array based on mana cost
	for (let i = 0; i < 15; i++) res.push({ mana: String(i) });
	cardDisplay.forEach(item => {
		const region = Helper.getObj(item["code"])["regionRef"];
		const mana = Helper.getObj(item["code"])["cost"];

		regionSet.add(region);

		if (res[mana][region]) res[mana][region] += item.count;
		else res[mana][region] = item.count;
	});

	// Pick bar color based on region defined in Color.js
	Array.from(regionSet).forEach(item => {
		regionColor.push(Color.region[item]);
	});

	// Return array version as required by "keys"
	let regionArr = Array.from(regionSet);
	return { res, regionArr, regionColor };
};

const DeckChart = ({ cardDisplay }) => {
	if (!cardDisplay) {
		return <></>;
	} else {
		const output = makeManaData(cardDisplay);
		return (
			<div
				style={{
					textAlign: "center",
					height: "inherit"
				}}
			>
				<div
					style={{
						color: Color.primary.fg,
						fontSize: "1rem",
						fontFamily: Font.default
					}}
				>
					法力曲線
				</div>
				<ResponsiveBar
					data={output.res}
					keys={output.regionArr}
					colors={output.regionColor}
					indexBy="mana"
					margin={{ top: 50, right: 50, bottom: 50, left: 50 }}
					padding={0.2}
					axisTop={null}
					axisRight={null}
					axisLeft={null}
					animate={true}
					motionStiffness={90}
					motionDamping={15}
					enableGridY={false}
					theme={{
						axis: {
							fontSize: "14px",
							tickColor: Color.primary.fg,
							ticks: {
								line: {
									stroke: Color.primary.fg
								},
								text: {
									fill: Color.primary.fg,
									fontSize: "0.9rem"
								}
							}
						},
						labels: {
							text: {
								fill: "red"
							}
						}
					}}
				/>
			</div>
		);
	}
};
export default DeckChart;
