import React from "react";
import { render } from "react-dom";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ReactGA from "react-ga";
import { createBrowserHistory } from "history";

// Router pages import
import Home from "./pages/Home";
import Cards from "./pages/Cards";
import CheckDecks from "./pages/CheckDecks";
import CustomDecks from "./pages/CustomDecks";
import Analysis from "./pages/Analysis";
import CardDetail from "./pages/CardDetail";
import Patches from "./pages/Patches";
import QuickCheckDeck from "./pages/QuickCheckDeck";

ReactGA.initialize("UA-158379995-1");
const history = createBrowserHistory();
history.listen(location => {
	ReactGA.set({ page: location.pathname });
	ReactGA.pageview(location.pathname);
});

render(
	<Router>
		<Switch>
			<Route path="/" exact component={Cards} />
			<Route path="/checkdecks" exact component={CheckDecks} />
			<Route path="/customdecks" exact component={CustomDecks} />
			<Route path="/cards" exact component={Cards} />
			<Route path="/analysis" exact component={Analysis} />
			<Route path="/patches" exact component={Patches} />

			<Route path="/cards/:cardcode" component={CardDetail} />
			<Route path="/:deckcode" component={QuickCheckDeck} />
		</Switch>
	</Router>,
	document.getElementById("root")
);
