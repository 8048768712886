import React from "react";

import Navbar from "../components/Navbar";

import { makeStyles } from "@material-ui/core/styles";
import { Grid, Zoom } from "@material-ui/core/";
import ZoomInIcon from "@material-ui/icons/ZoomIn";

import Helper from "../components/Helper";
import Color from "../assets/color";
import Font from "../assets/font";
import Global from "../assets/global";

let data;

const getImgSrc = type => {
	let suffix;

	switch (type) {
		case "regionRef":
			suffix = "region/" + data[type] + ".png";
			break;
		case "rarity":
			suffix = "rarity/" + data[type] + ".svg";
			break;
		case "keywords":
			suffix = "keywords/" + data[type] + ".svg";
			break;
		case "cardCode":
			suffix = "cards/" + data[type] + Global.EXT;
			break;
		case "mCardCode":
			suffix = "cards/" + data["cardCode"] + Global.EXT;
			break;
		case "cardFull":
			suffix = "cards/" + data["cardCode"] + "-full" + Global.EXT;
			break;
		case "fullhd":
			suffix = "fullhd/" + data["cardCode"] + "-full.png";
			break;
		default:
			break;
	}

	return Global.IMG_PREFIX + suffix;
};

const useStyles = makeStyles(theme => ({
	divQuote: {
		width: "50%",
		"@media (max-width: 600px)": {
			minWidth: "80%"
		}
	},
	gridQuote: {
		margin: "0",
		fontFamily: "'Alegreya SC', serif",
		fontSize: "20px",
		color: Color.dark.fg,
		borderTop: "double 4px " + Color.dark.fg,
		borderBottom: "double 4px " + Color.dark.fg,
		padding: "3% 0",
		textAlign: "center",

		"@media (max-width: 600px)": {
			fontSize: "15px"
		}
	},
	gridContainer: {
		height: "100vh"
	},
	gridCardDisplay: {
		justifyContent: "center",
		textAlign: "center",
		background: "transparent",
		display: "block",
		overflow: "auto"
	},
	imgCardDisplay: {
		height: "auto",
		maxHeight: "400px",
		"@media (max-width: 600px)": {
			height: "inherit"
		}
	},
	subImgCardDisplay: {
		height: "auto",
		maxHeight: "300px",
		"@media (max-width: 600px)": {
			height: "inherit"
		}
	},
	imgRegion: {
		width: "50px"
	},
	divDetailDisplay: {
		width: "100%",
		background: "#000"
	},
	btnImg: {
		height: "30px",
		marginLeft: "5%"
	},
	txtRegion: {
		display: "flex",
		color: "#fff",
		alignContent: "center",
		justifyContent: "center"
	},
	darken: {
		boxShadow: "0px 0px 1px 5000px rgba(0,0,0,0.8)"
	},
	assoTitle: {
		color: Color.primary.fg,
		fontSize: "1.2rem",
		fontFamily: Font.default
	},
	displayFull: {
		color: Color.primary.fg,
		fontSize: "1rem",
		fontFamily: Font.default,
		marginBottom: "3%",
		textDecoration: "none",
		display: "inline-flex",
		alignItems: "center"
	}
}));

const CardDetailGrid = ({ data }) => {
	const c = useStyles();
	const assoCardCodes = data["associatedCardRefs"];
	const assoLen = assoCardCodes.length;
	const AssoTitle = () => {
		if (!assoLen) return <></>;
		else {
			return (
				<Grid container item md={12} justify="center">
					<div className={c.assoTitle}>關聯卡牌</div>
				</Grid>
			);
		}
	};

	return (
		<>
			<Grid container item lg={6} md={4} xs={12} justify="center">
				<img
					src={getImgSrc("cardCode")}
					alt=""
					className={c.imgCardDisplay}
				/>
			</Grid>

			<Grid container item md={12} justify="center">
				<a
					className={c.displayFull}
					href={getImgSrc("fullhd")}
					target="_blank"
				>
					<ZoomInIcon />
					打開高清圖片
				</a>
			</Grid>

			<AssoTitle />
			{/* Foreach related cards, width = {4} */}
			{assoCardCodes.map(code => {
				return (
					<Grid container item md={3} xs={6} justify="center">
						<a href={"/cards/" + code}>
							<img
								src={
									Global.IMG_PREFIX +
									"cards/" +
									code +
									Global.EXT
								}
								alt=""
								className={c.subImgCardDisplay}
							/>
						</a>
					</Grid>
				);
			})}
		</>
	);
};

export default function CardDetail(props) {
	const c = useStyles();
	const cardcode = props.match.params.cardcode;

	data = Helper.getObj(cardcode);

	if (!data) {
		return <></>;
	}

	const getBGStyle = () => {
		let res = {};

		res["background"] =
			"linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url('" +
			getImgSrc("cardFull") +
			"')";
		return res;
	};

	return (
		<div>
			<Navbar />
			<Grid item md={12} className={c.darken}></Grid>
			<div className={c.gridContainer} style={getBGStyle()}>
				<Grid
					container
					justify="center"
					spacing={2}
					className={c.gridQuote}
				>
					<div className={c.divQuote}>"{data["flavorText"]}"</div>
				</Grid>

				<Grid container justify="center" spacing={2}>
					<CardDetailGrid data={data} />
				</Grid>
			</div>
		</div>
	);
}
