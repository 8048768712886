import React, { useState, useEffect } from "react";
import {
	Fab,
	Grid,
	Hidden,
	MenuList,
	MenuItem,
	Divider,
	makeStyles
} from "@material-ui/core/";
import FilterListIcon from "@material-ui/icons/FilterList";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

import Color from "../assets/color";
import Font from "../assets/font";
import MasterFilData from "../assets/masterFilter.json";
import Helper from "../components/Helper";

import FilterButton from "./FilterButton";

const useStyle = makeStyles(theme => ({
	leftNav: {
		background: Color.primary.bg,
		minWidth: "15%",
		color: Color.primary.fg,
		height: "calc(100% - 75px)",
		position: "fixed",
		overflow: "auto",

		"&::-webkit-scrollbar": {
			width: "0.4em"
		},
		"&::-webkit-scrollbar-track": {
			boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
			webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)"
		},
		"&::-webkit-scrollbar-thumb": {
			backgroundColor: "rgba(0,0,0,.1)",
			outline: "1px solid slategrey"
		},

		"@media (max-width: 600px)": {
			minWidth: "70%",
			height: "calc(100% - 80px)"
		}
	},
	leftNavSubTitle: {
		marginTop: "20px",
		fontWeight: 900,
		fontFamily: Font.default,
		"&:hover": {
			background: "inherit",
			textDecoration: "none"
		}
	},
	filItemContainer: {
		"&::-webkit-scrollbar": {
			width: "0.4em"
		},
		"&::-webkit-scrollbar-track": {
			boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
			webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)"
		},
		"&::-webkit-scrollbar-thumb": {
			backgroundColor: "rgba(0,0,0,.1)",
			outline: "1px solid slategrey"
		}
	},
	filterFab: {
		height: "70px !important",
		width: "70px !important",
		position: "fixed",
		right: "8%",
		bottom: "8%",
		boxShadow: "0 -2px 10px rgba(0, 0, 0, 1)",
		backgroundColor: Color.primary.fg + " !important"
	}
}));

// Data pre-processing
const getMasterData = type => {
	let res = [];
	MasterFilData[type].forEach(item => {
		res.push(item["name"]);
	});
	return res;
};

// Global var
const regionData = getMasterData("regionRef");
const rarityData = getMasterData("rarity");
const keywordsData = getMasterData("keywords");
const masterCardList = Array.from(Helper.getSetData()).sort((a, b) => {
	return a["cost"] - b["cost"];
});

const zhTrans = {
	regionRef: "地區",
	rarity: "稀有度",
	keywords: "技能"
};

const getFiltered = (filName, filStatus, filData, srcList) => {
	let res = [];
	const isAllChecked = filStatus.every(checked => {
		return !checked;
	});

	// If all checkbox not checked, return source list directly
	if (!isAllChecked) {
		for (let i in filData) {
			if (filStatus[i]) {
				Object.keys(srcList).forEach(key => {
					if (srcList[key][filName].includes(filData[i]))
						res.push(srcList[key]);
				});
			}
		}
	} else return srcList;

	return res;
};

const FilterButtonGroup = props => {
	let setCardListState;
	const cardListRef = props.cardListRef;
	useEffect(() => {
		setCardListState = cardListRef.current.setCardListState;
	});

	const c = useStyle();
	const [subItemToggle, setSubItemToggle] = useState({
		regionRef: true,
		rarity: true,
		keywords: true
	});
	// If the filter section will be displayed (for mobile fab)
	const [mobileToggled, setMobileToggle] = useState(true);

	// Boolean array for indicating if filters are selected
	const [regionStatus, updateRegionStatus] = useState(
		new Array(regionData.length).fill(false)
	);
	const [rarityStatus, updateRarityStatus] = useState(
		new Array(rarityData.length).fill(false)
	);
	const [keywordsStatus, updatekeywordsStatus] = useState(
		new Array(keywordsData.length).fill(false)
	);

	const applyFilter = () => {
		let res = masterCardList;

		res = getFiltered("regionRef", regionStatus, regionData, res);

		res = getFiltered("rarity", rarityStatus, rarityData, res);

		res = getFiltered("keywords", keywordsStatus, keywordsData, res);

		setCardListState(res);
	};

	const flipSubItemState = key => {
		var json = JSON.parse(JSON.stringify(subItemToggle));
		json[key] = !json[key];
		setSubItemToggle(json);
	};

	const FilButtonBeforeGrid = () => {
		let res = [];

		// For each filter section
		Object.keys(MasterFilData).forEach((key, i) => {
			let typeStatus;
			let typeUpdateState;
			if (key === "regionRef") {
				typeStatus = regionStatus;
				typeUpdateState = updateRegionStatus;
			} else if (key === "rarity") {
				typeStatus = rarityStatus;
				typeUpdateState = updateRarityStatus;
			} else if (key === "keywords") {
				typeStatus = keywordsStatus;
				typeUpdateState = updatekeywordsStatus;
			} else {
				typeStatus = null;
				typeUpdateState = null;
			}

			let itemTitle = (
				<div key={key}>
					<MenuItem
						className={c.leftNavSubTitle}
						onClick={() => {
							flipSubItemState(key);
						}}
					>
						{subItemToggle[key] ? (
							<KeyboardArrowDownIcon />
						) : (
							<KeyboardArrowRightIcon />
						)}
						{zhTrans[key]}
					</MenuItem>
					<Divider />
				</div>
			);

			// For each sub-filter section item
			let subitem = [];
			MasterFilData[key].forEach((item, i) => {
				subitem.push(
					<FilterButton
						key={item["name"]}
						type={key}
						args={item}
						pos={i}
						show={subItemToggle[key]}
						typeStatus={typeStatus}
						typeUpdateState={typeUpdateState}
						applyFilter={applyFilter}
						cardListRef={cardListRef}
					/>
				);
			});

			// Push the title first
			res.push(itemTitle);
			res.push(<div className={c.filItemContainer}>{subitem}</div>);
		});
		return res;
	};

	return (
		<>
			{mobileToggled && (
				<Grid
					item
					container
					sm={12}
					md={2}
					className={c.leftNavContainer}
				>
					<MenuList className={c.leftNav}>
						<FilButtonBeforeGrid />
					</MenuList>
				</Grid>
			)}

			<Hidden mdUp>
				<Fab
					className={c.filterFab}
					onClick={() => {
						setMobileToggle(!mobileToggled);
					}}
				>
					<FilterListIcon className={c.mobileFilIcon} />
				</Fab>
			</Hidden>
		</>
	);
};

export default FilterButtonGroup;
